import React from 'react';

import '../../styles/ChildRegistry/update-child-registry.css'

export default function UpdateChildRegistry() {
  /*
    peso: obrigatorio, numérico
    altura: obrigatorio, numérico
    qualquer campo do registro pode ser alterado também.
  */
  // rota da api: PUT /child/update/:id
  return (
    <h1>Update child registry</h1>
  );
}